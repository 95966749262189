<!--
 * @Description: 选择更换设备弹框
 * @Author: ChenXueLin
 * @Date: 2021-11-22 11:57:20
 * @LastEditTime: 2022-06-29 15:24:40
 * @LastEditors: ChenXueLin
-->
<template>
  <div>
    <el-dialog
      v-dialogDrag
      title="选择设备"
      :visible.sync="changeEquipVisible"
      width="900px"
      :before-close="handleClose"
      :close-on-click-modal="false"
      v-loading="dialogLoading"
      :element-loading-background="loadingBackground"
      custom-class="add-concat-dialog"
    >
      <div class="fee-content">
        <!-- 搜索头部 start -->
        <el-form class="search-list" ref="searchForm" :model="searchForm">
          <el-form-item class="search-item--1" prop="equipNameOrDeviceNumber">
            <el-input
              v-model="searchForm.equipNameOrDeviceNumber"
              placeholder="设备编号"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="search-item--1"
            prop="equipSource"
            v-if="!basicInfo.isGrayscaleWork"
          >
            <e6-vr-select
              v-model="searchForm.equipSource"
              :data="equipSourceList"
              placeholder="设备来源"
              title="设备来源"
            ></e6-vr-select>
          </el-form-item>
          <el-form-item class="search-item--buttons">
            <el-button type="primary" @click="handleSearch">查询</el-button>
            <!-- <el-button class="reset" @click="handleReset">重置</el-button> -->
          </el-form-item>
        </el-form>
        <!-- 搜索头部 end -->
        <template>
          <el-table
            border
            :data="equipList"
            highlight-current-row
            @select="select"
            @select-all="selectAll"
            @selection-change="handleDeleteionChange"
            ref="multipleTable"
          >
            <el-table-column
              type="selection"
              width="50"
              fixed
              :selectable="checkSelectable"
            ></el-table-column>
            <el-table-column
              show-overflow-tooltip
              v-for="(column, index) in columnData"
              :key="index"
              :prop="column.fieldName"
              :label="column.fieldLabel"
              :min-width="column.width"
              :fixed="column.fixed"
              :align="column.align"
              header-align="center"
            >
            </el-table-column>
          </el-table>
        </template>
        <!-- 分页 start -->
        <section
          class="pagination-wrapper fixed-section"
          ref="paginationWrapper"
        >
          <el-pagination
            :page-size.sync="searchForm.pageSize"
            :current-page.sync="searchForm.pageIndex"
            :page-sizes="pageSizes"
            :layout="layout"
            :total="total"
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          ></el-pagination>
        </section>
        <!-- 分页 end -->
      </div>
      <div class="dialog-footer" slot="footer">
        <el-button class="cancel" @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import {
  getUninstallProjectList,
  getCustormerEquip,
  getAllEquip,
  queryEngineerAllEquip,
  getClientAllEquip,
  getEngineerEquip,
  sendListDeliver
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "changeEquip",
  components: {},
  data() {
    return {
      columnData: [],
      equipList: [],
      total: 0,
      searchForm: {
        equipNameOrDeviceNumber: "",
        equipCode: "", //设备编号
        corpId: this.basicInfo.corpId,
        equipSource: 2, // 2:工程师1：客户3：项目
        pageIndex: 1,
        pageSize: 10
      },
      dialogLoading: false,
      total1: 0,
      total2: 0,
      total3: 0,
      equipSourceList: [], //设备来源
      selColumn: [], //选中的数据
      projectEquipList: [], //项目下设备
      engineerEquipList: [], //工程师名下设备
      custormerEquipList: [] //客户名下设备
    };
  },
  //安装单：taskSecondClass：1，2
  //isGrayscaleWork:1灰度客户0非灰度客户
  //isDeliverInstall:（1送装，2寄装）
  props: [
    "basicInfo",
    "changeEquipVisible",
    "equipRow",
    "taskSecondClass",
    "applyNo"
  ],
  mixins: [base, listPage],
  computed: {},
  watch: {
    changeEquipVisible: {
      immediate: true,
      handler(val) {
        if (val) {
          this.searchForm.corpId = this.basicInfo.corpId;
          if (this.basicInfo.isGrayscaleWork) {
            this.$nextTick(() => {
              this.queryList();
            });
          } else {
            this.setSelectData();
          }
          this.resetCloumn();
        }
      }
    }
  },
  mounted() {},
  methods: {
    checkSelectable(row) {
      let flag = false;
      if (!this.searchForm.equSource && row.isAvailable == 0) {
        flag = false;
      } else {
        flag = true;
      }
      return flag;
    },
    handleSearch() {
      this.searchForm.pageIndex = 1;
      this.columnData = [];
      this.equipList = [];
      this.resetCloumn();
      this.queryList();
    },
    resetCloumn() {
      let data = [
        {
          fieldName: "isNewString",
          display: true,
          fieldLabel: "新旧状态",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "thirdClassName",
          display: true,
          fieldLabel: "商品名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "firstClassName",
          display: true,
          fieldLabel: "商品分类",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "secondClassName",
          display: true,
          fieldLabel: "商品开票名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "equipNo",
          display: true,
          fieldLabel: "设备编号",
          width: 200,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "haveName",
          display: true,
          fieldLabel: "持有人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "ownerName",
          display: true,
          fieldLabel: "所属人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ];
      this.columnData = data;
    },
    setSelectData() {
      if (
        this.equipRow.customerSource == 0 &&
        this.equipRow.isEngineerOrProject == 0
      ) {
        this.equipSourceList = [];
      } else if (
        this.equipRow.customerSource == 0 &&
        this.equipRow.isEngineerOrProject == 1
      ) {
        this.equipSourceList = [
          { id: 2, label: "工程师" },
          { id: 3, label: "项目" }
        ];
      } else if (
        this.equipRow.customerSource != 0 &&
        this.equipRow.isEngineerOrProject == 1
      ) {
        this.equipSourceList = [
          { id: 2, label: "工程师" },
          { id: 1, label: "客户" },
          { id: 3, label: "项目" }
        ];
      } else {
        this.equipSourceList = [{ id: 1, label: "客户" }];
      }
      this.searchForm.equipSource = this.equipSourceList.length
        ? this.equipSourceList[0].id
        : "";
      if (
        this.$route.meta.operationIds.includes(122) &&
        (this.basicInfo.sceneType == 1 || this.basicInfo.sceneType == 4)
      ) {
        this.equipSourceList.push({
          id: 4,
          label: "按设备编号搜索"
        });
      }
      if (this.searchForm.equipSource) {
        this.queryList();
      }
    },
    //非灰度获取工程师名下设备
    async getEngineerEquip() {
      try {
        this.dialogLoading = true;
        let secondClassId;
        console.log;
        if (!this.basicInfo.isGrayscaleWork) {
          secondClassId = [1, 2, 7].includes(this.basicInfo.taskSecondClass)
            ? this.equipRow.secondClassId
            : "";
        } else {
          secondClassId = this.equipRow.secondClassId;
        }

        let res = await getEngineerEquip({
          employeeNo: this.basicInfo.engineerrpNo,
          ...this.searchForm,
          firstClassId: this.equipRow.firstClassId,
          secondClassId,
          thirdClassId: this.basicInfo.isGrayscaleWork
            ? this.equipRow.thirdClassId
            : ""
        });
        //工程师名下设备
        this.engineerEquipList = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.equipList = this.engineerEquipList;
        this.total = res.data.totalRecords;
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //非灰度获取客户名下设备
    async getCustormerEquip() {
      try {
        this.dialogLoading = true;
        let secondClassId;
        if (!this.basicInfo.isGrayscaleWork) {
          secondClassId = [1, 2, 7].includes(this.basicInfo.taskSecondClass)
            ? this.equipRow.secondClassId
            : "";
        } else {
          secondClassId = this.equipRow.secondClassId;
        }
        let res = await getCustormerEquip({
          corpId: this.basicInfo.corpId,
          ...this.searchForm,
          firstClassId: this.equipRow.firstClassId,
          secondClassId,
          needInstall: this.equipRow.customerSource,
          thirdClassId: this.basicInfo.isGrayscaleWork
            ? this.equipRow.thirdClassId
            : ""
        });
        //客户名下设备
        this.custormerEquipList = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.equipList = this.custormerEquipList.map(item => {
          item.equipNo = item.equipCode || item.equipNo;
          return item;
        });
        this.total = res.data.totalRecords;
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //非灰度查询所有设备
    async getAllEquipReq() {
      this.dialogLoading = true;
      try {
        let res = await getAllEquip({
          ...this.searchForm,
          equipType: this.equipRow.equType,
          installDetailId: this.equipRow.installDetailId,
          equipOp: this.equipRow.equipOp,
          equipCode: this.searchForm.equipNameOrDeviceNumber
        });
        //所有设备
        this.allEquipList = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.equipList = this.allEquipList.map(item => {
          item.equipNo = item.equipCode || item.equipNo;
          return item;
        });
        this.total = res.data.totalRecords;
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //获取项目下设备
    async getUninstallProjectList() {
      try {
        this.dialogLoading = true;
        let secondClassId = [1, 2, 7].includes(this.basicInfo.taskSecondClass)
          ? this.equipRow.secondClassId
          : "";
        let res = await getUninstallProjectList({
          corpId: this.basicInfo.corpId,
          projectId: this.basicInfo.projectId,
          ...this.searchForm,
          firstClassId: this.equipRow.firstClassId,
          secondClassId
        });
        //项目下设备
        this.projectEquipList = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.equipList = this.projectEquipList;
        this.total = res.data.totalRecords;
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //获取寄装设备
    async sendListDeliverReq() {
      try {
        this.dialogLoading = true;
        let res = await sendListDeliver({
          corpId: this.basicInfo.corpId,
          ...this.searchForm,
          firstClassId: this.equipRow.firstClassId,
          secondClassId: this.equipRow.secondClassId,
          thirdClassId: this.equipRow.thirdClassId,
          applyNo: this.applyNo
        });
        this.equipList = res.data.array.map(item => {
          item.equipNo = item.equipCode || item.equipNo;
          return item;
        });
        this.total = res.data.totalRecords;
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //灰度获取工程师名下设备
    async queryEngineerAllEquipReq() {
      try {
        this.dialogLoading = true;
        let res = await queryEngineerAllEquip({
          ...this.searchForm,
          companyNo: this.basicInfo.companyNo,
          materialNo: this.equipRow.materialNo,
          equipCode: this.searchForm.equipNameOrDeviceNumber,
          employeeId: this.basicInfo.engineerrpId,
          isUsed: 0
        });
        //工程师名下设备
        this.engineerEquipList = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.equipList = this.engineerEquipList;
        this.total = res.data.totalRecords;
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //灰度获取客户名下设备
    async getClientAllEquipReq() {
      try {
        this.dialogLoading = true;
        let res = await getClientAllEquip({
          ...this.searchForm,
          materialNo: this.equipRow.materialNo,
          jobId: this.basicInfo.itemId,
          gpsno: this.searchForm.equipNameOrDeviceNumber
        });
        //客户名下设备
        this.custormerEquipList = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.equipList = this.custormerEquipList.map(item => {
          item.equipNo = item.equipCode || item.equipNo;
          return item;
        });
        this.total = res.data.totalRecords;
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    //处理请求
    queryList() {
      let equSource;
      console.log(
        this.basicInfo.isDeliverInstall,
        this.equipRow.equType,
        this.basicInfo.sourceOrganization
      );
      if (this.basicInfo.isGrayscaleWork) {
        //寄装、主机、e6yun
        if (
          this.basicInfo.isDeliverInstall == 2 &&
          this.equipRow.equType == 1 &&
          this.basicInfo.sourceOrganization == 1
        ) {
          console.log(11111);
          equSource = 1;
          this.getClientAllEquipReq();
        }
        //寄装、配件(客户名下设备)、e6yun
        if (
          this.basicInfo.isDeliverInstall == 2 &&
          this.equipRow.equType != 1 &&
          this.basicInfo.sourceOrganization == 1
        ) {
          equSource = 1;
          this.sendListDeliverReq();
        }
        //寄装、主机、g7s
        if (
          this.basicInfo.isDeliverInstall == 2 &&
          this.basicInfo.sourceOrganization == 2 &&
          this.equipRow.equType == 1
        ) {
          equSource = 1;
          this.getClientAllEquipReq();
        }
        //送装（工程师名下设备）
        if (this.basicInfo.isDeliverInstall != 2) {
          equSource = 2;
          this.queryEngineerAllEquipReq();
        }
        this.equSource = equSource;
      } else {
        switch (this.searchForm.equipSource) {
          case 1:
            this.getCustormerEquip();
            break;
          case 2:
            this.getEngineerEquip();
            break;
          case 3:
            this.getUninstallProjectList();
            break;
          case 4:
            this.getAllEquipReq();
            break;
        }
      }
    },
    // 表格勾选时间
    handleDeleteionChange(columns) {
      this.selColumn = columns;
    },
    select(selection) {
      if (selection.length > 1) {
        let del_row = selection.shift();
        this.$refs.multipleTable.toggleRowSelection(del_row, false);
      }
    },
    selectAll(selection) {
      if (selection.length > 1) {
        selection.length = 1;
      }
    },
    //点击确定
    handleConfirm() {
      if (!this.selColumn.length) {
        this.$message.warning("请先勾选设备");
        return;
      }
      let equSource;
      if (!this.basicInfo.isGrayscaleWork) {
        equSource =
          this.searchForm.equipSource == 4
            ? this.selColumn[0].belongType == 4
              ? 1
              : 2
            : this.searchForm.equipSource;
      } else {
        equSource = this.equSource;
      }
      this.$emit("updateEquip", {
        ...this.selColumn[0],
        equSource
      });
      this.handleClose();
    },

    //重置数据
    resetData() {
      this.selColumn = [];
      this.searchForm = {
        equipCode: "", //设备编号
        equipNameOrDeviceNumber: "",
        equipSource: 2,
        pageIndex: 1,
        pageSize: 10
      };
      this.$refs.multipleTable.clearSelection();
      this.$refs.searchForm.resetFields();
    },
    handleClose() {
      this.resetData();
      this.$emit("close");
    }
  }
};
</script>
<style lang="scss" scoped></style>
